import { Button, Col, Form, Input, message, Modal, Row, Select, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createStripeAccount } from '../../api/point';
import { Sidebar } from '../../container/Sidebar';
import { addPartnerData, getPartnerData } from '../../script/auth';
import "./style.css";

export default function PartnerPage() {

  const [newPartnerModal, setNewPartnerModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [partnerList, setPartnerList] = useState([]);


  const navigate = useNavigate();

  const init = () => {
    setLoading(true);
    getPartnerData()
      .then((response) => {
        setPartnerList(response);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        message.error("Something went wrong while fetch partner data. Please try again later!");
        setLoading(false);
      })
  }

  const phoneMask = (text) => {
    var x = text.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    const num = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
    return num;
  }

  const partnerTableColumns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Accounts',
      dataIndex: 'accounts',
      key: 'accounts'
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email'
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
      render: (e) => <span>{phoneMask(e)}</span>
    },
    {
      title: 'Commission',
      dataIndex: 'commission',
      key: 'commission',
    },
    {
      title: 'Term',
      dataIndex: 'term',
      key: 'term',
      render: (e) => e == 0 ? <span>Perpetuity</span> : null
    },
    {
      title: 'Action',
      dataIndex: 'id',
      key: 'action',
      render: (e) =>
        <div>
          <Button size='small' type="primary" onClick={() => navigate("/partner-detail", { state: e })}>View</Button>
        </div>
    }
  ];


  useEffect(() => {
    if (localStorage.getItem('user')) {
      const user = JSON.parse(localStorage.getItem("user"));
      if (user.role == 1) {
        navigate("/login");
      } else {
        init();
      }
    }
    else navigate("/login");
  }, [])

  const createNewPartner = (values) => {
    setLoading(true);

    createStripeAccount({ email: values.email })
      .then((response) => {
        if (response.data.status == 'success') {
          values.stripe_account = response.data.response.account.id;
          values.stripe_account_link = response.data.response.accountLink.url;
          values.stripe_status = 0;
          addPartnerData(values)
            .then((response) => {
              setLoading(false);
              init();
              setNewPartnerModal(false);
            })
            .catch((err) => {
              console.log(err);
              setLoading(false);
              message.error("Something went wrong while saving partner data. Please try again later!");
            })
        } else {
          setLoading(false);
          message.error("Something went wrong while saving partner data. Please try again later!");
        }
      })
      .catch((err) => {
        console.log(err);
        message.error("Something went wrong while saving partner data. Please try again later!");
        setLoading(false);
      })

  }

  return (
    <Sidebar>
      <Row className='top-container'>
        <Col span={12}>
          <h3 className='page-title'>Partner</h3>
        </Col>
        <Col span={12} className="top-add-button-container">
          <Button onClick={() => setNewPartnerModal(true)}>Add</Button>
        </Col>
      </Row>

      <Row>
        <Col span={24}>
          <Table
            rowKey={record => record._id}
            loading={loading}
            columns={partnerTableColumns}
            dataSource={partnerList}
          />
        </Col>
      </Row>

      <Modal
        title="Add Partner"
        open={newPartnerModal}
        onCancel={() => setNewPartnerModal(false)}
        footer={false}
      >
        <Row>
          <Col span={24}>
            <Form
              layout="vertical"
              wrapperCol={{ span: 24 }}
              onFinish={createNewPartner}
              onFinishFailed={() => message.error("Something went wrong, please try again later!")}
              autoComplete="off"
            >
              <Row gutter={[12, 0]}>
                <Col span={12}>
                  <Form.Item
                    label="Name"
                    name="name"
                    rules={[{ required: true, message: 'Please input name!' }]}
                  >
                    <Input
                      size="medium"
                      placeholder="Name"
                    />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    label="Email"
                    name="email"
                    rules={[{ required: true, message: 'Please input email!' }]}
                  >
                    <Input
                      size="medium"
                      placeholder="Email Address"
                    />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    label="Address"
                    name="address"
                    rules={[{ required: true, message: 'Please input address!' }]}
                  >
                    <Input
                      size="medium"
                      placeholder="Address"
                    />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    label="Phone"
                    name="phone"
                    rules={[{ required: true, message: 'Please input phone!' }]}
                  >
                    <Input
                      size="medium"
                      placeholder="Phone Number"
                    />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    label="Commission"
                    name="commission"
                    rules={[{ required: true, message: 'Please input commission!' }]}
                  >
                    <Input
                      type="number"
                      size="medium"
                      placeholder="Commission"
                    />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    label="Term"
                    name="term"
                    rules={[{ required: true, message: 'Please choose a term!' }]}
                  >
                    <Select
                      placeholder="Please choose a term!"
                      options={[
                        {
                          label: "Perpetuity",
                          value: 0
                        }
                      ]}
                    />
                  </Form.Item>
                </Col>
              </Row>


              <Form.Item wrapperCol={{ span: 24 }} className="text-center">
                <Button
                  type="primary"
                  size={"medium"}
                  htmlType="submit"
                  loading={loading}
                >
                  Create
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Modal>
    </Sidebar>
  );
}
