import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import 'firebase/auth';
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyD51u-N7c1wTtdX_OD8CxVrnupXCn4f2Fg",
  authDomain: "protocol-pro.firebaseapp.com",
  projectId: "protocol-pro",
  storageBucket: "protocol-pro.appspot.com",
  messagingSenderId: "305913123563",
  appId: "1:305913123563:web:0e2bcb04a8a46d45f1e4ae",
  measurementId: "G-8WK507076X"
};

export const FBApp = initializeApp(firebaseConfig);
export const FBDB = getFirestore(FBApp);
export const FBAUTH = getAuth(FBApp);
export const BUCKET_NAME = "protocol-pro.appspot.com";
export const PROCESSOR_ACCESS_TOKEN = "ya29.a0Ael9sCPYIx-l9TEhZNnBGDZ7W_M4APglDWp_uZpGqXJe3iIMdyWFBiycqq2EQD9vuJ9nY-07s9Q7p-sTGUgjtbNujXp3pQtTVNifrloo4VRmkuSTlZNXHwIVra09A_FbyVIo47brHYau8QrHY3pwwzywnslUAyr_aCgYKAVcSARASFQF4udJhNvdeswPqS6nosJ9UaecpMg0167";
export const FBActionCodeSetting = {
  url: process.env.NODE_ENV === 'production' ? 'https://protocol-pro.firebaseapp.com/client-password-reset' : 'http://localhost:3000/client-password-reset',
  handleCodeInApp: true,
}