import { ExportOutlined, LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Card, Col, Form, Input, message, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { siteLogin } from '../script/auth';
import "./style.css";

export default function LoginScreen() {

    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);

    const onFinish = async (values) => {
        setLoading(true)
        siteLogin(values)
            .then((response) => {
                setLoading(false)
                if (response) {
                    response.role = 0;
                    localStorage.setItem("user", JSON.stringify(response));
                    navigate("/client");
                } else {
                    message.error("Please input valid credential!");
                }
            })
            .catch((err) => {
                console.log(err);
            })
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const init = () => {
        if (localStorage.getItem('user')) {
            const data = JSON.parse(localStorage.getItem("user"));
            if (data.role == 0) {
                navigate("/client");
            }
        }
    }

    useEffect(() => {
        init();
    }, [])

    return (
        <Row align={"middle"}>
            <Col xs={{ span: 22, offset: 1 }} md={{ span: 12, offset: 6 }}>
                <Card style={{ marginTop: "20%" }}>
                    <Row>
                        <Col span={24} className="text-center">
                            <img src={require("../assets/image/logo.png")} className="logo" />
                        </Col>

                        <Col span={24} style={{ marginTop: 50 }}>
                            <Form
                                layout="vertical"
                                wrapperCol={{ span: 24 }}
                                initialValues={{ remember: true }}
                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}
                                autoComplete="off"
                            >
                                <Form.Item
                                    label="Email"
                                    name="email"
                                    rules={[{ required: true, message: 'Please input your email!' }]}
                                >
                                    <Input
                                        size="large"
                                        placeholder="Email"
                                        prefix={<UserOutlined />}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label="Password"
                                    name="password"
                                    rules={[{ required: true, message: 'Please input your password!' }]}
                                >
                                    <Input.Password
                                        size="large"
                                        placeholder="Password"
                                        prefix={<LockOutlined />}
                                    />
                                </Form.Item>
                                <Form.Item wrapperCol={{ span: 24 }} className="text-center">
                                    <Button
                                        type="primary"
                                        icon={<ExportOutlined />}
                                        size={"large"}
                                        htmlType="submit"
                                        loading={loading}
                                    >
                                        Log In
                                    </Button>
                                </Form.Item>
                            </Form>
                        </Col>
                    </Row>
                </Card>
            </Col>
        </Row>
    );
}
