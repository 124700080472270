import { addDoc, collection, deleteDoc, doc, getDoc, getDocs, query, updateDoc, where } from "firebase/firestore";
import { FBDB } from "./fireStore";

export const addDocumentData = async (param) => {
  try {
    if (param.parent) {

      const q = query(collection(FBDB, "documents"), where("parent", "==", param.parent));
      const querySnapshot = await getDocs(q);

      const documentData = querySnapshot.docs
        .map((doc) => ({ ...doc.data(), id: doc.id }));

      documentData.map(async (document) => {
        const ref = doc(FBDB, "documents", document.id);
        await updateDoc(ref, {
          status: 0
        });
      })

      const parentRef = doc(FBDB, "documents", param.parent)
      await updateDoc(parentRef, { status: 0 })
      const docRef = await addDoc(collection(FBDB, "documents"), param);
      return { status: "success", data: docRef.id };

    } else {
      const docRef = await addDoc(collection(FBDB, "documents"), param);
      return docRef.id;
    }
  }
  catch (err) {
    console.log(err);
    return null;
  }
}


export const getClientDocumentByID = async (param) => {
  try {
    const q = query(collection(FBDB, "documents"),
      where("client", "==", param.client),
      where("status", "==", 1)
    );

    const querySnapshot = await getDocs(q);
    const documentData = querySnapshot.docs
      .map((doc) => ({ ...doc.data(), id: doc.id }));
    return documentData;
  } catch (error) {
    console.log(error);
    return {};
  }
}

export const getDocumentByParent = async (param) => {
  try {
    const docRef = doc(FBDB, "documents", param.id);
    const docSnap = await getDoc(docRef);
    var result = [];
    if (docSnap.exists()) {
      result = [{ ...docSnap.data(), id: docSnap.id, ref: docSnap.ref }]
    }
    const q = query(collection(FBDB, "documents"), where("parent", "==", param.id));

    const querySnapshot = await getDocs(q);
    const documentData = querySnapshot.docs
      .map((doc) => ({ ...doc.data(), id: doc.id }));

    const response = result.concat(documentData);
    return response;

  } catch (error) {
    console.log(error);
    return error;
  }
}

export const removeDocumentData = async (id) => {
  try {
    await deleteDoc(doc(FBDB, "documents", id));
    return { status: "success" }
  } catch (err) {
    console.log(err);
    return { status: "failed", err: err };
  }
}

export const updateDocumentStatus = async (id) => {
  try {
    const ref = doc(FBDB, "documents", id)
    return updateDoc(ref, {
      status: 1
    }).then(response => {
      return { status: "success" }
    }).catch(error => {
      return { status: "failed", err: error }
    })
  } catch (err) {
    console.log(err);
    return { status: "failed", err: err };
  }
}

export const updateDocumentInfo = async (param) => {
  try {
    const ref = doc(FBDB, "documents", param.id)
    return updateDoc(ref, {
      version: param.version,
      notification: param.notification,
      created_date: param.created_date,
      page: param.page,
    }).then(response => {
      return { status: "success" }
    }).catch(error => {
      return { status: "failed", err: error }
    })
  } catch (err) {
    console.log(err);
    return { status: "failed", err: err };
  }
}

export const updateVideoInfo = async (param) => {
  try {
    const ref = doc(FBDB, "documents", param.id)
    return updateDoc(ref, {
      version: param.version,
      notification: param.notification,
      created_date: param.created_date,
      videoTitle: param.videoTitle,
      youtube: param.youtube
    }).then(response => {
      return { status: "success" }
    }).catch(error => {
      return { status: "failed", err: error }
    })
  } catch (err) {
    console.log(err);
    return { status: "failed", err: err };
  }
}
