import axios from "axios";

export const baseUrl =
  process.env.NODE_ENV === 'production'
    ? 'https://protocol-pro.uc.r.appspot.com/'
    : 'http://localhost:8080/';

const api = axios.create({
  baseURL: baseUrl,
  headers: {
    "Accept": "application/json",
    "Content-Type": "application/json",
    "CORS_ALLOW_ALL_ORIGINS": true,
    "Access-Control-Allow-Origin": "*",
  },
});

export default api;
