import { Button, Col, Form, Input, message, Modal, Row, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Sidebar } from '../../container/Sidebar';
import { getPartnerDataById, updatePartnerData } from '../../script/auth';

export default function PartnerDetail() {

  const location = useLocation();
  const navigate = useNavigate();

  const [partnerData, setPartnerData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [editPartnerModal, setEditPartnerModal] = useState(false);

  const init = () => {
    setLoading(true);
    getPartnerDataById({ id: location.state })
      .then((response) => {
        setPartnerData(response);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        message.error("Something went wrong while fetching client data, please try again later!");
      })
  }


  const phoneMask = (text) => {
    var x = text.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    const num = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
    return num;
  }

  useEffect(() => {
    if (location.state)
      init();
    else
      navigate("/partners");
  }, [])

  const goToStripeSignUp = () => {

  }

  const updatePartner = (values) => {

    setLoading(true);
    updatePartnerData({ id: location.state, info: values })
      .then((response) => {
        setLoading(false);
        setEditPartnerModal(false);
        message.success("Partner data has been updated!");
        init();
      })
      .catch((err) => {
        message.error("Something went wrong while updating partner data, please try again later!");
        setLoading(false);
      })
  }


  return (
    <Sidebar>
      <Row>
        <Col span>
          <h4>{partnerData?.organization}</h4>
        </Col>
        <Col span={24}>
          <Row>
            <Col span={6} className="tab-container">
              <Row>
                <Col span={24} className="contact-line-view">
                  <h4>Contact</h4>
                  <Button className='edit-button' onClick={() => setEditPartnerModal(true)}>Edit</Button>
                </Col>
                <Col span={24} className="info-list-view">
                  <span>Name: {partnerData?.name}</span>
                  <span>Address: {partnerData?.address}</span>
                  <span>Phone: {partnerData ? phoneMask(partnerData?.phone) : ""}</span>
                  <span>Email: {partnerData?.email}</span>
                </Col>
              </Row>
            </Col>
            <Col span={12} className="tab-container">
              <Row>
                <Col span={24} className="contact-line-view">
                  <h4>Revenue Share</h4>
                </Col>
                <Col span={24} className="info-list-view">
                  <span>{partnerData?.commission}%</span>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col span={6} className="tab-container">
              <Row>
                <Col span={24} className="contact-line-view">
                  <h4>Stripe Connect Dashboard</h4>
                  <Button className='edit-button' onClick={() => goToStripeSignUp()}>View</Button>
                </Col>
                <Col span={24} className="info-list-view">
                  <span>Status - {partnerData?.stripe_status == 1 ? "Account connected" : "Needs Attention"}</span>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row >



      <Modal
        title="Add Partner"
        open={editPartnerModal}
        onCancel={() => setEditPartnerModal(false)}
        footer={false}
      >
        <Row>
          <Col span={24}>
            <Form
              layout="vertical"
              wrapperCol={{ span: 24 }}
              onFinish={updatePartner}
              onFinishFailed={() => message.error("Something went wrong, please try again later!")}
              autoComplete="off"
              initialValues={partnerData}
            >
              <Row gutter={[12, 0]}>
                <Col span={12}>
                  <Form.Item
                    label="Name"
                    name="name"
                    rules={[{ required: true, message: 'Please input name!' }]}
                  >
                    <Input
                      size="medium"
                      placeholder="Name"
                    />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    label="Email"
                    name="email"
                    rules={[{ required: true, message: 'Please input email!' }]}
                  >
                    <Input
                      size="medium"
                      placeholder="Email Address"
                    />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    label="Address"
                    name="address"
                    rules={[{ required: true, message: 'Please input address!' }]}
                  >
                    <Input
                      size="medium"
                      placeholder="Address"
                    />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    label="Phone"
                    name="phone"
                    rules={[{ required: true, message: 'Please input phone!' }]}
                  >
                    <Input
                      size="medium"
                      placeholder="Phone Number"
                    />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    label="Commission"
                    name="commission"
                    rules={[{ required: true, message: 'Please input commission!' }]}
                  >
                    <Input
                      type="number"
                      size="medium"
                      placeholder="Commission"
                    />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    label="Term"
                    name="term"
                    rules={[{ required: true, message: 'Please choose a term!' }]}
                  >
                    <Select
                      placeholder="Please choose a term!"
                      options={[
                        {
                          label: "Perpetuity",
                          value: 0
                        }
                      ]}
                    />
                  </Form.Item>
                </Col>
              </Row>


              <Form.Item wrapperCol={{ span: 24 }} className="text-center">
                <Button
                  type="primary"
                  size={"medium"}
                  htmlType="submit"
                  loading={loading}
                >
                  Create
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Modal>
    </Sidebar >
  );
}
