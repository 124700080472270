import { Button, Col, Form, Input, message, Modal, Row, Table } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Sidebar } from '../../container/Sidebar';
import { addAdminData, getAdminData, updateAdminData } from '../../script/auth';

export default function AdminPage() {

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [newAdminModal, setNewAdminModal] = useState(false);
  const [adminList, setAdminList] = useState([]);
  const [selectedAdmin, setSelectedAdmin] = useState(null);
  const [editAdminModal, setEditAdminModal] = useState(false);
  const [form] = Form.useForm();

  const init = () => {
    setLoading(true);
    getAdminData()
      .then((response) => {
        setAdminList(response);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        message.error("Something went wrong while fetch client data. Please try again later!");
        setLoading(false);
      })
  }

  useEffect(() => {
    if (localStorage.getItem('user')) {
      const user = JSON.parse(localStorage.getItem("user"));
      if (user.role == 1) {
        navigate("/login");
      } else {
        init();
      }
    }
    else navigate("/login");
  }, [])


  const adminColumns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Action',
      render: (e) =>
        <div>
          <Button size='small' type="primary" onClick={() => {
            setSelectedAdmin(e)
            setEditAdminModal(true);
          }}>
            Edit
          </Button>
        </div>
    }
  ];

  const createNewAdmin = (values) => {
    setLoading(true);
    values.email = values.email.toLowerCase();
    addAdminData(values)
      .then((response) => {
        setLoading(false);
        init();
        setNewAdminModal(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        message.error("Something went wrong while saving admin data. Please try again later!");
      })
  }

  const updateAdmin = (values) => {
    setLoading(true);
    values.email = values.email.toLowerCase();
    updateAdminData({ id: selectedAdmin.id, info: values })
      .then((response) => {
        setLoading(false);
        setEditAdminModal(false);
        message.success("Admin data has been updated!");
        init();
      })
      .catch((err) => {
        message.error("Something went wrong while updating admin data, please try again later!");
        setLoading(false);
      })
  }

  return (
    <Sidebar>
      <Row className='top-container'>
        <Col span={12}>
          <h3 className='page-title'>Admin</h3>
        </Col>
        <Col span={12} className="top-add-button-container">
          <Button onClick={() => setNewAdminModal(true)}>Add</Button>
        </Col>
      </Row>
      <Row>
        <Col span={24} className="">
          <Table
            loading={loading}
            dataSource={adminList}
            columns={adminColumns}
          />
        </Col>
      </Row>


      <Modal
        title="Add New Admin"
        open={newAdminModal}
        onCancel={() => setNewAdminModal(false)}
        footer={false}
      >
        <Row>
          <Col span={24}>
            <Form
              layout="vertical"
              wrapperCol={{ span: 24 }}
              onFinish={createNewAdmin}
              onFinishFailed={() => message.error("Something went wrong, please try again later!")}
              autoComplete="off"
            >
              <Row gutter={[12, 0]}>
                <Col span={24}>
                  <Form.Item
                    label="Admin Name"
                    name="name"
                    rules={[{ required: true, message: 'Please input name!' }]}
                  >
                    <Input
                      size="medium"
                      placeholder="Admin Name"
                    />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    label="Email"
                    name="email"
                    rules={[{ required: true, message: 'Please input email!' }]}
                  >
                    <Input
                      size="medium"
                      placeholder="Email"
                    />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    label="Password"
                    name="password"
                    rules={[{ required: true, message: 'Please input password!' }]}
                  >
                    <Input.Password
                      size="medium"
                      placeholder="Password"
                    />
                  </Form.Item>
                </Col>
              </Row>


              <Form.Item wrapperCol={{ span: 24 }} className="text-center">
                <Button
                  type="primary"
                  size={"medium"}
                  htmlType="submit"
                  loading={loading}
                >
                  Create
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Modal>

      <Modal
        title="Edit Admin"
        form={form}
        open={editAdminModal}
        onCancel={() => setEditAdminModal(false)}
        footer={false}
      >
        <Row>
          <Col span={24}>
            <Form
              layout="vertical"
              initialValues={selectedAdmin}
              wrapperCol={{ span: 24 }}
              onFinish={updateAdmin}
              onFinishFailed={() => message.error("Something went wrong, please try again later!")}
              autoComplete="off"
            >
              <Row gutter={[12, 0]}>
                <Col span={24}>
                  <Form.Item
                    label="Admin Name"
                    name="name"
                    rules={[{ required: true, message: 'Please input name!' }]}
                  >
                    <Input
                      size="medium"
                      placeholder="Admin Name"
                    />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    label="Email"
                    name="email"
                    rules={[{ required: true, message: 'Please input email!' }]}
                  >
                    <Input
                      size="medium"
                      placeholder="Email"
                    />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    label="Password"
                    name="password"
                    rules={[{ required: true, message: 'Please input password!' }]}
                  >
                    <Input.Password
                      size="medium"
                      placeholder="Password"
                    />
                  </Form.Item>
                </Col>
              </Row>


              <Form.Item wrapperCol={{ span: 24 }} className="text-center">
                <Button
                  type="primary"
                  size={"medium"}
                  htmlType="submit"
                  loading={loading}
                >
                  Update
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Modal>
    </Sidebar>
  );
}
