import api from "./config";

export async function getSuggestWord(data) {
  const result = api.post("/get_suggest_word", data);
  return result;
}

export async function getSearchResult(data) {
  const result = api.post("/get_search_result", data);
  return result;
}

export async function sendInvoiceToCustomer(data) {
  const result = api.post("/send_invoice", data);
  return result;
}

export async function convertDocumentAi(data) {
  const result = api.post("/convert-document-ai", data);
  return result;
}

export async function convertAndUpdateDocumentAi(data) {
  const result = api.post("/convert-and-update-document-ai", data);
  return result;
}

export async function createStripeAccount(data) {
  const result = api.post("/create_stripe_account", data);
  return result;
}

export async function createStripeAccountLink(data) {
  const result = api.post("/create_stripe_account_link", data);
  return result;
}

export async function sendPasswordSetEmail(data) {
  const result = api.post("/send_password_set_email", data);
  return result;
}

export async function sendNotification(data) {
console.log(data)
  const result = api.post("/send_content_notification", data);
  return result;
}

export async function sendTestNotification(data) {
  const result = api.post("/send_test_notification", data);
  return result;
}
