import { Button, message, Result } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { createStripeAccountLink } from '../../api/point';
import { getPartnerDataByAccountId, updateStripeAccountLink } from '../../script/auth';

export default function RefreshPage() {

  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [accountData, setAccountData] = useState(null);

  useEffect(() => {
    try {
      const account = atob(searchParams.get("account"));
      getPartnerDataByAccountId({ id: account })
        .then((response) => {
          setAccountData(response[0]);
        })
        .catch((err) => {
          console.log(err);
        })
    }
    catch (err) {
      console.log(err);
    }
  }, [])

  const generateNewLink = () => {
    if (accountData) {
      setLoading(true);
      createStripeAccountLink({ account: atob(searchParams.get("account")), email: atob(searchParams.get("email")) })
        .then((response) => {
          if (response.data.status == "success") {
            updateStripeAccountLink({ id: accountData.id, link: response.data.response.url })
              .then((response) => {
                setLoading(false);
                message.success("We just sent an email to your inbox. please check and complete your form to get payout!")
              })
              .catch((err) => {
                setLoading(false);
                message.error("Something went wrong while generating new account link, please try again later!");
              })
          } else {
            setLoading(false);
            message.error("Something went wrong while generating new account link, please try again later!");
          }
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        })
    } else {
      message.error("This link is not a valid link");
    }
  }

  return (
    <Result
      status="info"
      title={accountData ? "This link has been expired!" : "This link is not a valid link!"}
      subTitle={accountData ? "You can still refresh a link by clicking below button. We will send you new link via email" : "You can only refresh a acount on valid link!"}
      extra={[
        <Button type="primary" key="console" loading={loading} onClick={generateNewLink} disabled={accountData ? false : true}>
          Refresh a link
        </Button>
      ]}
    />
  )
}
