import {
  Button,
  Col,
  Form,
  Input,
  message,
  Modal,
  Popconfirm,
  Row,
  Table,
} from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Sidebar } from "../../container/Sidebar";
import {
  addClientData,
  getClientData,
  removeClientData,
} from "../../script/auth";
import "./style.css";

export default function ClientPage() {
  const [newClientModal, setNewClientModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [clientList, setClientList] = useState([]);

  const navigate = useNavigate();

  const init = () => {
    setLoading(true);
    getClientData()
      .then((response) => {
        setClientList(response);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        message.error(
          "Something went wrong while fetch client data. Please try again later!"
        );
        setLoading(false);
      });
  };

  const phoneMask = (text) => {
    var x = text.replace(/\D/g, "").match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    const num = !x[2]
      ? x[1]
      : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
    return num;
  };

  const clientTableColumns = [
    {
      title: "Organization Name",
      dataIndex: "organization",
      key: "organization",
    },
    {
      title: "Users",
      dataIndex: "users",
      key: "users",
      render: (e) => <span>{e?.length}</span>,
    },
    {
      title: "Partner",
      dataIndex: "partner",
      key: "partner",
      render: (e) => <span>{e ? e : "Not Assigned Yet"}</span>,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
      render: (e) => <span>{phoneMask(e)}</span>,
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      render: (e) => (
        <div>
          <Button
            size="small"
            type="primary"
            onClick={() => navigate("/client-detail", { state: e })}
          >
            View
          </Button>
          <Popconfirm
            placement="right"
            title={"Confirm!"}
            description={"Are you sure you want to remove this client?"}
            onConfirm={() => removeClient(e)}
            okText="Yes"
            cancelText="No"
          >
            <Button
              type="primary"
              size="small"
              danger
              style={{ marginLeft: 2 }}
            >
              Delete
            </Button>
          </Popconfirm>
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (localStorage.getItem("user")) {
      const user = JSON.parse(localStorage.getItem("user"));
      if (user.role == 1) {
        navigate("/login");
      } else {
        init();
      }
    } else navigate("/login");
  }, []);

  const removeClient = (user) => {
    console.log(user);
    setLoading(true);
    removeClientData({ id: user })
      .then((response) => {
        setLoading(false);
        if (response.status == "success") {
          init();
          message.success("Client has been removed!");
        } else
          message.error(
            "Something went wrong while removing a client data, please try again later!"
          );
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        message.error(
          "Something went wrong while removing a client data, please try again later!"
        );
      });
  };

  const createNewClient = (values) => {
    setLoading(true);
    values.users = [];
    values.partner = null;

    addClientData(values)
      .then((response) => {
        setLoading(false);
        setNewClientModal(false);
        init();
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        message.error(
          "Something went wrong while saving client data. Please try again later!"
        );
      });
  };

  return (
    <Sidebar>
      <Row className="top-container">
        <Col span={12}>
          <h3 className="page-title">Client</h3>
        </Col>
        <Col span={12} className="top-add-button-container">
          <Button onClick={() => setNewClientModal(true)}>Add</Button>
        </Col>
      </Row>

      <Row>
        <Col span={24}>
          <Table
            rowKey={(record) => record._id}
            loading={loading}
            columns={clientTableColumns}
            dataSource={clientList}
          />
        </Col>
      </Row>

      <Modal
        title="Add Client"
        open={newClientModal}
        onCancel={() => setNewClientModal(false)}
        footer={false}
      >
        <Row>
          <Col span={24}>
            <Form
              layout="vertical"
              wrapperCol={{ span: 24 }}
              onFinish={createNewClient}
              onFinishFailed={() =>
                message.error("Something went wrong, please try again later!")
              }
              autoComplete="off"
            >
              <Row gutter={[12, 0]}>
                <Col span={12}>
                  <Form.Item
                    label="Organization Name"
                    name="organization"
                    rules={[
                      { required: true, message: "Please input organization!" },
                    ]}
                  >
                    <Input size="medium" placeholder="Organization Name" />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    label="Client Name"
                    name="name"
                    rules={[{ required: true, message: "Please input name!" }]}
                  >
                    <Input size="medium" placeholder="Client Name" />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    label="Address"
                    name="address"
                    rules={[
                      { required: true, message: "Please input address!" },
                    ]}
                  >
                    <Input size="medium" placeholder="Address" />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    label="Phone Number"
                    name="phone"
                    rules={[
                      { required: true, message: "Please input phone number!" },
                    ]}
                  >
                    <Input size="medium" placeholder="Phone Number" />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    label="Email"
                    name="email"
                    rules={[{ required: true, message: "Please input email!" }]}
                  >
                    <Input size="medium" placeholder="Email" />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    label="Organization Domain"
                    name="domain"
                    rules={[
                      { required: true, message: "Please input domain name!" },
                    ]}
                  >
                    <Input size="medium" placeholder="Organization Domain" />
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item wrapperCol={{ span: 24 }} className="text-center">
                <Button
                  type="primary"
                  size={"medium"}
                  htmlType="submit"
                  loading={loading}
                >
                  Create
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Modal>
    </Sidebar>
  );
}
