import { Button, Col, Form, Input, message, Modal, Progress, Row, Select, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { convertDocumentAi } from '../../api/point';
import { ClientSideBar } from '../../container/ClientSideBar';
import { addDocumentData, getClientDocumentByID } from '../../script/document';
import { fileUploadToStroage } from '../../script/file';
import { sendContentNotification } from '../../script/auth';

export default function ClientContent() {

    const navigate = useNavigate();
    const [userData, setUserData] = useState(null);
    const [documentData, setDocumentData] = useState([]);
    const [createContentModal, setCreateContentModal] = useState(false);
    const [createLoading, setCreateLoading] = useState(false);
    const [documentFile, setDocumentFile] = useState(null);
    const [contentType, setContentType] = useState(0);
    const [loading, setLoading] = useState(false);
    const [documentType, setDocumentType] = useState(0);
    const [uploadPercent, setUploadPercent] = useState(1);

    const { TextArea } = Input;
    const [documentForm] = Form.useForm();

    const dateOption = {
        timeZone: 'America/New_York',
        year: 'numeric',
        month: 'numeric',
        day: 'numeric'
    };

    const documentColumn = [
        {
            title: 'Name',
            key: "name",
            dataIndex: "name",
        },
        {
            title: 'Version',
            key: "version",
            dataIndex: "version",
        },
        {
            title: 'Date',
            key: "created_date",
            dataIndex: "created_date",
            render: (e) => <span>{new Date(e).toLocaleString('en-US', dateOption)}</span>
        },
        {
            title: 'Status',
            key: "status",
            dataIndex: "status",
            render: (e) => e == 0 ? <span>Waiting Approval</span> : <span>Active</span>
        },
        {
            title: 'Section',
            key: "section",
            dataIndex: "section",
            render: (e) => e == 0 ? <span>EMS</span> : <span>Fire</span>
        },
        {
            title: 'Type',
            key: "type",
            dataIndex: "type",
            render: (e) => e == 0 ? <span>Document</span> : <span>Video</span>
        },
        {
            title: 'Action',
            key: "action",
            render: (e) =>
                <Button type="primary" size='small' onClick={() => navigate("/document-detail", { state: e })}>View</Button>
        }
    ]

    const getContent = (client) => {
        setLoading(true)
        getClientDocumentByID({ client: client })
            .then((response) => {
                setDocumentData(response);
                setLoading(false)
            })
            .catch((err) => {
                console.log(err);
                message.error("Something went wrong while fetching client document data, please try again later!");
                setLoading(false)
            })
    }

    const init = async () => {
        const data = await localStorage.getItem("user");
        try {
            if (data) {
                const user = JSON.parse(data);
                if (user.role == 0) {
                    navigate("/client-login");
                } else {
                    setUserData(user.client);
                    getContent(user.client.id)
                }
            }
        }
        catch (err) {
            console.log(err)
            navigate("/client-login");
        }
    }

    useEffect(() => {
        if (localStorage.getItem('user')) {
            const user = JSON.parse(localStorage.getItem("user"));
            if (user.role == 0) {
                navigate("/client-login");
            } else {
                init();
            }
        }
        else navigate("/client-login");
    }, [])


    const addDocument = (values) => {
        if (values.type == 0) {
            if (documentFile) {
                setCreateLoading(true);
                // message.info("This may take a few minutes, so please do not close this page.")
                const documentName = new Date().getTime() + "-" + documentFile.name;

                fileUploadToStroage({ file: documentFile, name: documentName }, (snapshot) => {
                    setUploadPercent(parseInt(snapshot.bytesTransferred * 100 / snapshot.totalBytes))
                })
                    .then((response) => {
                        setCreateLoading(false);
                        setCreateContentModal(false);
                        const uri = response;
                        const gcs_uri = "gs://protocol-pro.appspot.com/documents/" + documentName;
                        values.document = uri;
                        values.created_date = (new Date()).toISOString();
                        values.status = 1;
                        values.client = userData.id;

                        documentForm.resetFields();

                        convertDocumentAi({ document: gcs_uri, values: values })
                            .then((response) => {
                                message.success("New document has been created!");
                            })
                            .catch((err) => {
                                console.log(err)
                                message.error("Something went wrong while uploading document, please try again later!");
                            })

                    })
                    .catch((err) => {
                        console.log(err);
                        setCreateLoading(false);
                        message.error("Something went wrong while uploading document, please try again later!");
                    })
            } else {
                message.error("Something went wrong while uploading document, please try again later!");
            }
        } else {
            values.created_date = new Date().toString();
            values.status = 1;
            values.client = userData.id;

            addDocumentData(values)
                .then(async (response) => {
                    message.success("Document has been created!");
                    await sendContentNotification({
                        text: values.notification,
                        client: userData.id
                    })
                    init();
                    setCreateLoading(false);
                    setCreateContentModal(false);
                    window.location.reload(false);
                })
                .catch((err) => {
                    console.log(err);
                    setCreateLoading(false);
                    message.error("Something went wrong while uploading document, please try again later!");
                })
        }
    }

    const DocumentView = () => {
        return (
            <Row>
                <Col span={24} className="create-invoice-container">
                    <Button type="primary" onClick={() => setCreateContentModal(true)}>Add New Content</Button>
                </Col>
                <Col span={24}>
                    <Table
                        rowKey={record => record.id}
                        dataSource={documentData}
                        columns={documentColumn}
                        loading={loading}
                    />
                </Col>
            </Row>
        )
    }


    return (
        <ClientSideBar>
            <DocumentView />


            <Modal
                title="Add Content"
                open={createContentModal}
                onCancel={() => setCreateContentModal(false)}
                footer={false}
            >
                <Row>
                    <Col span={24}>
                        <Form
                            form={documentForm}
                            layout="vertical"
                            wrapperCol={{ span: 24 }}
                            onFinish={addDocument}
                            onFinishFailed={() => message.error("Something went wrong, please try again later!")}
                            autoComplete="off"
                        >
                            <Row gutter={[12, 0]}>
                                <Col span={12}>
                                    <Form.Item
                                        label="Content Name"
                                        name="name"
                                        rules={[{ required: true, message: 'Please input content name!' }]}
                                    >
                                        <Input
                                            size="medium"
                                            placeholder="Content Name"
                                        />
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item
                                        label="Version"
                                        name="version"
                                        rules={[{ required: true, message: 'Please input version!' }]}
                                    >
                                        <Input
                                            size="medium"
                                            placeholder="Version"
                                            type='number'
                                        />
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item
                                        label="Section"
                                        name="section"
                                        rules={[{ required: true, message: 'Please choose section!' }]}
                                    >
                                        <Select
                                            placeholder="Please choose section"
                                            options={[
                                                {
                                                    value: 0,
                                                    label: "EMS"
                                                },
                                                {
                                                    value: 1,
                                                    label: "Fire"
                                                }
                                            ]}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item
                                        label="Type"
                                        name="type"
                                        rules={[{ required: true, message: 'Please choose type!' }]}
                                    >
                                        <Select
                                            placeholder="Please choose a document type"
                                            options={[
                                                {
                                                    value: 0,
                                                    label: "Document"
                                                },
                                                {
                                                    value: 1,
                                                    label: "Video"
                                                }
                                            ]}
                                            onChange={(e) => setDocumentType(e)}
                                        />
                                    </Form.Item>
                                </Col>

                                {
                                    documentType == 0 ?
                                        <Col span={24}>
                                            <Form.Item
                                                label="Document"
                                                name="document"
                                                rules={[{ required: true, message: 'Please upload document!' }]}
                                            >
                                                <input name="document" type="file" onChange={(e) => setDocumentFile(e.target.files[0])} />
                                            </Form.Item>
                                        </Col>
                                        :
                                        <Col span={24}>
                                            <Row gutter={12}>
                                                <Col span={12}>
                                                    <Form.Item
                                                        label="Title"
                                                        name="videoTitle"
                                                        rules={[{ required: true, message: 'Please input video title!' }]}
                                                    >
                                                        <Input
                                                            size="medium"
                                                            placeholder="Title"
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={12}>
                                                    <Form.Item
                                                        label="Youtube Link"
                                                        name="youtube"
                                                        rules={[{ required: true, message: 'Please input youtube link!' }]}
                                                    >
                                                        <Input
                                                            size="medium"
                                                            placeholder="Youtube Link"
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Col>
                                }

                                <Col span={24}>
                                    <Form.Item
                                        label="Notification Text"
                                        name="notification"
                                        rules={[{ required: true, message: 'Please input notification text!' }]}
                                    >
                                        <TextArea
                                            size="medium"
                                            placeholder="Notification Text"
                                        />
                                    </Form.Item>
                                </Col>

                                {
                                    createLoading &&
                                    <Col span={24}>
                                        <Progress percent={uploadPercent} />
                                    </Col>
                                }


                            </Row>

                            <Form.Item wrapperCol={{ span: 24 }} className="text-center">
                                <Button
                                    type="primary"
                                    size={"medium"}
                                    htmlType="submit"
                                    loading={createLoading}
                                >
                                    Create Document
                                </Button>
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
            </Modal>
        </ClientSideBar>
    )
}
